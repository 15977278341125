@import '~antd/dist/antd.css';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

html,
body,
div,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  font-family: 'Inter';
  font-size: 100%;
  vertical-align: baseline;
  color: #595959;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

// CUSTOM THEME START
.ant-form-item {
  .ant-input:focus,
  .ant-input:hover {
    border-color: #384e40;
  }

  .ant-input-password:focus,
  .ant-input-password:hover {
    border-color: #384e40;
  }

  .ant-picker:hover,
  .ant-picker.ant-picker-large.ant-picker-focused {
    border-color: #384e40;
  }

  .ant-form-item-control-input-content {
    .ant-select-selector:hover {
      border-color: #384e40;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #384e40;
    color: #384e40;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-arrow {
    .anticon {
      color: #384e40;
    }
  }
}

.ant-select-dropdown {
  .rc-virtual-list {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #f1fbfb;
    }
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f1fbfb;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #384e40;
}

.ant-picker-header-view button:hover {
  color: #384e40;
}
// CUSTOM THEME END

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: center;
  padding: 10px;
  min-height: calc(100vh - 90px);
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 64px);
  }
}
.container-bg {
  background: #f0f0f0;
  border-radius: 8px;
  width: 100%;
  height: 100vh;
}
.link-text {
  color: #1890ff;
  cursor: pointer;
}
.ant-form-item {
  margin-bottom: 24px;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0;
  font-size: 16px;
  margin-bottom: 8px;
}

.h1-text {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #141414;
  margin-bottom: 8px;
  display: flex;
  &.mobile {
    font-size: 27px;
  }
}

.h2-text {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #141414;
  margin-bottom: 8px;
  display: flex;
}

.h3-text {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 38px;
  color: #141414;
  margin-bottom: 8px;
  display: flex;
  &.mobile {
    font-size: 22px;
  }
}

.m-h3-text {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #141414;
  display: flex;
  margin: 5rem 1rem 1rem 1rem;
  text-align: center;
}

.h4-text {
  color: #595959;
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
  display: flex;
}

.h5-text {
  color: #595959;
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 16px;
  display: flex;
}

.m-h5-text {
  color: #595959;
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  margin: 0.5rem 1rem 1rem 1rem;
  text-align: center;
}

.ant-layout-header {
  padding: 0 54px;
}
.small-button {
  background: none;
  border: 1px solid #1890ff;
  border-radius: 5px;
  color: #1890ff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 6px;
}
.badge-default {
  background: #f0f0f0;
  border-radius: 24px;
  border: 1px solid #d9d9d9;
  padding: 6px 12px;
}
.center-div {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.primary-button {
  color: #ffffff;
  background-color: #384e40 !important;
  border-color: #384e40;
}
.primary-button:hover {
  background-color: #384e40 !important;
  border-color: #384e40;
}

.green-button {
  color: #ffffff;
  background-color: #1c2d24 !important;
  border-color: #1c2d24;
}

.disabled-button {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: 0;
  box-shadow: none;
}

.ant-modal-mask {
  z-index: 1003;
}
.ant-modal-wrap {
  z-index: 1004;
}

.ant-message {
  top: 10px;
  .ant-message-notice-content:has(
      .ant-message-custom-content.ant-message-error
    ) {
    border: 1px solid;
    background-color: #fff1f0;
    border-color: #ffccc7;
    .ant-message-custom-content.ant-message-error {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #000;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 16px !important;
    }
  }
}

.toast-element {
  width: 100%;
  display: block;
  border-radius: 5px;
  &-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon {
      margin-right: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      font-size: 14px;
    }
  }
  &-error {
    background: #ffccc7;
    border: 1px solid #ffccc7;
    border-radius: 2px;
    .toast-element-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon {
        background: #ff4d4f;
        border-radius: 50%;
        height: 20px;
        width: 20px;
      }
    }
  }
  .ant-notification-notice-message {
    margin-bottom: 0px;
  }
  .ant-notification-notice-close {
    position: absolute;
    top: 4px;
    right: 8px;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
  }
}
body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.ant-tooltip {
  .ant-tooltip-inner {
    text-align: center;
  }
}

@media print {
  @page {
    size: 297mm 350mm;
    margin: 14mm;
  }
  .ant-layout-header {
    display: none !important;
  }
}

.ant-form-item .ant-input {
  border-radius: 8px;
}